<template>
  <div class="col-span-4 flex flex-col items-center md:col-span-6">
    <img
      :src="images[icon]"
      :alt="icon"
      class="mb-8 h-20 w-20"
    >
    <h3 class="mb-4">
      {{ title }}
    </h3>
    <p class="text-lg leading-loose">
      {{ text }}
    </p>
  </div>
</template>

<script setup lang="ts">
import {filename} from 'pathe/utils';

defineProps<{
  icon: 'snel-schakelen' | 'persoonlijk' | 'maatwerk' | 'talentpool' | 'resultaten' | 'efficientie'
  title: string
  text: string
}>();

const glob = import.meta.glob('@/assets/icon/*.svg', {eager: true});
const images = Object.fromEntries(
  Object.entries(glob).map(([key, value]) => [filename(key), value.default]),
);
</script>
